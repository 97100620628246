document.addEventListener('DOMContentLoaded', () => {
  // watch video
  document.querySelectorAll('.video-placeholder__thumbnail').forEach((el) => {
    el.addEventListener('click', (e) => {
      const isHosted = el.getAttribute('data-hosted') === 'true' ?? false;
      el.nextElementSibling.hidden = false;
      if (el.querySelector('.video-placeholder__play')) {
        el.querySelector('.video-placeholder__play').classList.add('hidden');
      }
      el.classList.add('video-placeholder__thumbnail--playing');
      if (isHosted) {
        el.nextElementSibling.play();
      } else {
        el.nextElementSibling.src = el.nextElementSibling.dataset.src;
      }
      return false;
    });
  });
});
