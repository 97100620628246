import { throttle } from './utilities.js';

let lastScrollTop = 0;
let scrollDownDistance = 0;
let scrollUpDistance = 0;

function headerScrollVisibility() {
  const st = window.scrollY || document.documentElement.scrollTop;
  if (st > lastScrollTop) {
    scrollUpDistance = 0;
    scrollDownDistance += st - lastScrollTop;
    if (scrollDownDistance > 100) {
      document.querySelector('#main').classList.add('is-header-hidden');
    }
  } else {
    scrollDownDistance = 0;
    scrollUpDistance += st - lastScrollTop;
    if (scrollUpDistance < -50) {
      document.querySelector('#main').classList.remove('is-header-hidden');
    }
  }
  lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
}

function scrolledOffTop() {
  if (window.scrollY > 50) {
    document.querySelector('#main').classList.add('is-scrolled-off-top');
  } else {
    document.querySelector('#main').classList.remove('is-scrolled-off-top');
  }
}

headerScrollVisibility();
document.addEventListener('scroll', throttle(headerScrollVisibility, 10));

scrolledOffTop();
document.addEventListener('scroll', throttle(scrolledOffTop, 10));
