// init marquees

import Marquee from 'vanilla-marquee';

function moreDuplications(el) {
  setTimeout(function () {
    // Get first instance of marquee
    const instance = el.querySelector('.js-marquee');

    // Clone it
    const clone = instance.cloneNode(true);
    const clone2 = instance.cloneNode(true);

    // Insert it twice
    instance.after(clone, clone2);
  }, 10);
}

function setupMarquees() {
  document.querySelectorAll('[data-marquee]').forEach((el) => {
    /* eslint-disable no-new */
    const marquee = new Marquee(el, {
      duplicated: true,
      gap: 0,
      speed: 50,
      startVisible: true,
      recalcResize: true,
      delayBeforeStart: 10000,
      pauseOnHover: false,
      beforeStarting: moreDuplications(el),
    });

    // Refresh soon after init to fix speed
    setTimeout(function () {
      marquee.refresh();
    }, 250);
  });
}

document.addEventListener('DOMContentLoaded', function () {
  setTimeout(function () {
    setupMarquees();
  }, 0);
});
