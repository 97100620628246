import Swiper from 'swiper';
import {
  Navigation,
  Controller,
  Autoplay,
  EffectCoverflow,
} from 'swiper/modules';
import 'swiper/css';

function setupServiceSliders() {
  // Get all sliders
  const servicesSliders = document.querySelectorAll('.js-services-slider');

  for (let i = 0; i < servicesSliders.length; i++) {
    const sliderParent = servicesSliders[i];

    const mainSlider = sliderParent.querySelector('.js-services-main-slider');
    const imagesSlider = sliderParent.querySelector(
      '.js-services-image-slider'
    );
    const slideCount = mainSlider.querySelectorAll('.swiper-slide').length;
    const prevBtn = sliderParent.querySelector('.swiper-button-prev');
    const nextBtn = sliderParent.querySelector('.swiper-button-next');

    // Skip this one if we can't find the sliders within
    if (mainSlider == null || imagesSlider == null) {
      continue;
    }

    if (slideCount < 2) {
      continue;
    }

    // Set up main slider
    const mainSliderSwiper = new Swiper(mainSlider, {
      // configure Swiper to use modules
      modules: [Navigation, Controller, Autoplay, EffectCoverflow],
      direction: 'horizontal',
      speed: 750,
      effect: 'coverflow',
      coverflowEffect: {
        slideShadows: false,
      },
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
      autoplay: {
        delay: 5000,
        pauseOnMouseEnter: true,
      },
    });

    // Set up images slider
    const imagesSliderSwiper = new Swiper(imagesSlider, {
      // configure Swiper to use modules
      modules: [Navigation, Controller, Autoplay, EffectCoverflow],
      direction: 'horizontal',
      speed: 750,
      // effect: 'coverflow',
      loop: true,
      // autoplay: {
      //   delay: 3000,
      //   pauseOnMouseEnter: true,
      // },
    });

    // Link the 2 sliders
    mainSliderSwiper.controller.control = imagesSliderSwiper;
    imagesSliderSwiper.controller.control = mainSliderSwiper;

    // Show the buttons
    prevBtn.classList.remove('vis-hidden');
    nextBtn.classList.remove('vis-hidden');

    mainSliderSwiper.on('slideChangeTransitionEnd', function () {
      deactivateSlideLink(this);
    });
  }
}

function deactivateSlideLink(swiper) {
  const slides = swiper.slides;

  for (let i = 0; i < slides.length; i++) {
    const slide = slides[i];
    const slideLink = slide.querySelector('a.services-slider__main-slide');

    if (slide.classList.contains('swiper-slide-active')) {
      slideLink.removeAttribute('tabindex');
    } else {
      slideLink.setAttribute('tabindex', '-1');
    }
  }
}

function setupFeatureSliders() {
  // Get all sliders
  const featureSliders = document.querySelectorAll('.js-feature-slider');

  for (let i = 0; i < featureSliders.length; i++) {
    const slider = featureSliders[i];
    const slideCount = slider.querySelectorAll('.swiper-slide').length;

    if (slideCount < 2) {
      continue;
    }

    // Set up slider
    /* eslint-disable-next-line no-unused-vars */
    const featureSliderSwiper = new Swiper(slider, {
      // configure Swiper to use modules
      modules: [Autoplay],
      direction: 'horizontal',
      speed: 750,
      loop: true,
      autoplay: {
        delay: 5000,
      },
    });
  }
}

document.addEventListener('DOMContentLoaded', () => {
  setupServiceSliders();
  setupFeatureSliders();
});
