/**
 * Get the width of the scrollbar
 */
function getScrollbarWidth() {
  // Create the measurement node
  const scrollDiv = document.createElement('div');
  const root = document.documentElement;

  scrollDiv.className = 'scrollbar-measure';
  document.body.appendChild(scrollDiv);

  // Get the scrollbar width
  const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

  // Add it as a custom property
  root.style.setProperty('--scrollbar-width', scrollbarWidth + 'px');

  // Delete the DIV
  document.body.removeChild(scrollDiv);
}

export function getMenuElements() {
  const menuElements = {
    menuToggle: document.querySelector('.js-menu-toggle'),
    overlayMenu: document.querySelector('.js-overlay-menu'),
    body: document.querySelector('body'),
    menuClosers: document.querySelectorAll('.js-menu-close'),
    headerLeft: document.querySelector('.js-header-left'),
    headerRight: document.querySelector('.js-header-right'),
    appInner: document.querySelector('.js-app-inner'),
  };

  return menuElements;
}

function closeOverlayMenu() {
  const { menuToggle, overlayMenu, body, headerLeft, appInner } =
    getMenuElements();

  body.classList.remove('overlay-menu-open');
  menuToggle.setAttribute('aria-expanded', 'false');
  overlayMenu.classList.remove('is-open');

  // Remove inert from non menu elements
  appInner.removeAttribute('inert');
  headerLeft.removeAttribute('inert');
}

function openOverlayMenu() {
  const { menuToggle, overlayMenu, body, headerLeft, appInner } =
    getMenuElements();

  body.classList.add('overlay-menu-open');
  menuToggle.setAttribute('aria-expanded', 'true');
  overlayMenu.classList.add('is-open');

  // Set non-menu elements to inert
  appInner.setAttribute('inert', 'true');
  headerLeft.setAttribute('inert', 'true');
}

export function isOverlayMenuOpen() {
  const { body } = getMenuElements();
  const isOpen = body.classList.contains('overlay-menu-open');

  return isOpen;
}

function toggleOverlayMenu() {
  getScrollbarWidth();

  if (isOverlayMenuOpen()) {
    closeOverlayMenu();
  } else {
    openOverlayMenu();
  }
}

// Toggle button
function setupMenuButtons() {
  const { menuToggle, overlayMenu, menuClosers } = getMenuElements();

  if (overlayMenu && menuToggle) {
    menuToggle.addEventListener('click', toggleOverlayMenu);
  }

  // Close buttons
  if (overlayMenu && menuClosers) {
    for (let i = 0; i < menuClosers.length; i++) {
      menuClosers[i].addEventListener('click', closeOverlayMenu);
    }
  }

  // Esc key to close
  document.addEventListener('keydown', function (e) {
    if (e.key === 'Escape' && isOverlayMenuOpen()) {
      closeOverlayMenu();
    }
  });
}

// ----------------------
// Current page indicator
// ----------------------
function currentPageIndicator() {
  let currentPage = '';
  /* eslint-disable no-undef */
  if (typeof setCurrentPage !== 'undefined') {
    currentPage = setCurrentPage;
  }
  let currentSection = '';
  /* eslint-disable no-undef */
  if (typeof setCurrentSection !== 'undefined') {
    currentSection = setCurrentSection;
  }

  // Add underline to current link
  if (typeof currentPage !== 'undefined' && currentPage) {
    const pages = document.querySelectorAll(
      '.js-nav-link[data-slug="' + currentPage + '"]'
    );
    if (pages) {
      pages.forEach((page) => {
        page.classList.add('is-here');
      });
    }
  }

  // If current section, expand <details> element
  if (typeof currentSection !== 'undefined' && currentSection) {
    const section = document.querySelector(
      '.js-nav-details[data-section="' + currentSection + '"]'
    );
    if (section) {
      section.open = true;
    }
  }
}

// ----------------------
// Sidebar subnav
// ----------------------
function sidebarSubnav() {
  const { overlayMenu } = getMenuElements();

  // get all js nav links
  const navLinks = document.querySelectorAll('.js-nav-link');

  // get all sidebar subnavs
  const sidebarSubnavs = document.querySelectorAll('.js-sidebar-subnav');

  // Shows a subnav
  function showSubnav(slug) {
    for (let j = 0; j < sidebarSubnavs.length; j++) {
      const subnav = sidebarSubnavs[j];
      const navLink = document.querySelector(
        '.js-nav-link-with-children[data-slug="' +
          subnav.dataset.parentSlug +
          '"]'
      );

      if (subnav.dataset.parentSlug === slug) {
        subnav.classList.add('visible');
        navLink.classList.add('subnav-visible');
      } else {
        subnav.classList.remove('visible');
        navLink.classList.remove('subnav-visible');
      }
    }
  }

  // loop over links
  for (let i = 0; i < navLinks.length; i++) {
    const navLink = navLinks[i];
    const slug = navLink.dataset.slug;

    if (navLink.classList.contains('js-nav-link-with-children')) {
      navLink.addEventListener('mouseenter', function () {
        showSubnav(slug);
      });

      navLink.addEventListener('focus', function () {
        showSubnav(slug);
      });
    } else if (!navLink.classList.contains('menu__subnav-link')) {
      navLink.addEventListener('mouseenter', function () {
        showSubnav(null);
      });
    }
  }

  overlayMenu.addEventListener('mouseleave', function () {
    showSubnav(null);
  });
}

document.addEventListener('DOMContentLoaded', () => {
  getScrollbarWidth();

  setupMenuButtons();

  currentPageIndicator();

  sidebarSubnav();
});
