// ╭─────────―╮
// │ In-view  │
// ╰──────────╯

// Usage:

// <div class="iv-once">'Visible' class is added once</div>

// <div class="iv-once-children">
//     <div>'Visible' class is added once to each child</div>
//     <div>'Visible' class is added once to each child</div>
//     <div>'Visible' class is added once to each child</div>
// </div>

// <div class="iv-repeat">'Visible' class is added when in viewport, and removed when not in viewport</div>

// Init GSAP & scrollTrigger plugin
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export function getInViewElements() {
  // Get all iv elements
  const ivEls = document.querySelectorAll(
    '.iv-once, .iv-once-children > *, .iv-repeat'
  );
  return ivEls;
}

export function setupInViewTrigger() {
  const ivEls = getInViewElements();

  // Only create scrollTrigger if needed
  if (ivEls.length > 0) {
    // Create scrollTrigger
    ScrollTrigger.create({
      start: 0,
      end: 9999999999999,
      onUpdate: checkInView,
    });
  }
}

/**
 * Checks state of in-view elements and updates accordingly
 */
export function checkInView() {
  // const ivThreshold = 0.5; // Set viewport threshold
  const ivEls = getInViewElements();

  // Loops over all in-view elements
  for (let i = 0; i < ivEls.length; i++) {
    const ivEl = ivEls[i];

    // Checks this element's state and whether it repeats
    const hasVisibleClass = ivEl.classList.contains('visible');
    const isInViewport = ScrollTrigger.isInViewport(ivEl);
    const repeats = ivEl.classList.contains('iv-repeat');

    if (!hasVisibleClass && isInViewport) {
      // Add the visible class if it is in the viewport and doesn't already have the class
      ivEl.classList.add('visible');
    } else if (hasVisibleClass && !isInViewport && repeats) {
      // Removes the visible class only if it repeats
      ivEl.classList.remove('visible');
    }
  }
}

setupInViewTrigger();
checkInView();
