// import { initPage } from './init';
import { checkInView } from './in-view';

const filters = [];

document.addEventListener('DOMContentLoaded', () => {
  window.addEventListener(
    'popstate',
    function (event) {
      if (history.state && history.state.id === 'filter') {
        location.reload();
      }
    },
    false
  );

  function filterLoadUrl(id, url, reload = true) {
    document
      .querySelector('.filter-results[data-id="' + id + '"]')
      .classList.add('is-loading');

    let loadUrl = url;
    if (!reload) {
      if (url.indexOf('?') !== -1) loadUrl += '&loadmore=1';
      else loadUrl += '?loadmore=1';
    }

    fetch(loadUrl)
      .then(function (response) {
        return response.text();
      })
      .then(function (html) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // push state
        if (filters[id].updateUrl) {
          const title = doc.querySelector('title').textContent;
          window.history.pushState(
            {
              id: 'filter',
            },
            title,
            url
          );
        }

        if (reload) {
          document.querySelector(
            '.filter-results[data-id="' + id + '"]'
          ).innerHTML = doc.querySelector(
            '.filter-results[data-id="' + id + '"]'
          ).innerHTML;
          // document.querySelector('.filters select[name="year"]').innerHTML =
          //   doc.querySelector('.filters select[name="year"]').innerHTML;
        } else {
          document.querySelector(
            '.filter-results[data-id="' + id + '"] .articles'
          ).innerHTML =
            document.querySelector(
              '.filter-results[data-id="' + id + '"] .articles'
            ).innerHTML +
            doc.querySelector('.filter-results[data-id="' + id + '"] .articles')
              .innerHTML;
          if (
            doc.querySelector(
              '.filter-results[data-id="' + id + '"] .load-more'
            )
          ) {
            document.querySelector(
              '.filter-results[data-id="' + id + '"] .load-more'
            ).innerHTML = doc.querySelector(
              '.filter-results[data-id="' + id + '"] .load-more'
            ).innerHTML;
          } else {
            document
              .querySelector('.filter-results[data-id="' + id + '"] .load-more')
              .remove();
          }
        }
        document
          .querySelector('.filter-results[data-id="' + id + '"]')
          .classList.remove('is-loading');

        // init filter
        filterInit(id);
        // initPage();
        checkInView();

        // activate block links
        document
          .querySelectorAll('.filter-results[data-id="' + id + '"] .block-link')
          .forEach((el) => {
            el.addEventListener('click', function (e) {
              el.querySelector('a').click();
            });
          });
      })
      .catch(function (err) {
        console.log('Failed to fetch page: ', err);
      });
  }

  function filterSearch(id, jumpToUrl = false) {
    const params = {};
    const query = {};
    let isFiltered = false;

    // loop through inputs to find values
    document
      .querySelectorAll('.filters[data-id="' + id + '"] .filter')
      .forEach((el) => {
        if (el.querySelector('.input')) {
          const id = el.getAttribute('data-id');
          const type = el.getAttribute('data-type');
          const val = el.querySelector('.input').value;
          if (val) {
            isFiltered = true;
            if (type === 'query') {
              query[id] = val;
            } else {
              params[id] = val;
            }
          }
          el.classList.toggle('is-active', val);
        }
      });

    // make url
    const globalParamsOrder = [
      'category',
      'location',
      'service',
      'sector',
      'month',
      'page',
    ];
    let url = filters[id].rootUrl;
    let sep = '';
    globalParamsOrder.forEach((key) => {
      if (params[key] !== undefined && params[key] !== 'all') {
        // if (key == 'page') {
        url += sep + key;
        sep = '/';
        // }
        url += sep + params[key];
        sep = '/';
      }
    });
    for (const key in params) {
      if (Object.prototype.hasOwnProperty.call(params, key)) {
        if (
          params[key] !== undefined &&
          params[key] !== 'all' &&
          globalParamsOrder.indexOf(key) === -1
        ) {
          url += sep + key;
          sep = '/';
          url += sep + params[key];
        }
      }
    }
    url += sep;

    // add query string
    sep = '';
    let urlQuery = '';
    for (const key in query) {
      if (Object.prototype.hasOwnProperty.call(query, key)) {
        if (query[key] !== undefined && query[key].length) {
          urlQuery += sep + key + '=' + encodeURIComponent(query[key]);
          sep = '&';
        }
      }
    }
    if (urlQuery.length) {
      if (filters[id].rootUrlHasQueryString) urlQuery = '&' + urlQuery;
      else urlQuery = '?' + urlQuery;
      url = url + urlQuery;
    }

    let newUrl = url.replace(window.location.host, '');
    newUrl = newUrl.replace(window.location.protocol, '');
    newUrl = newUrl.replace('//', '');
    const currentUrl = window.location.pathname + window.location.search;

    if (newUrl !== currentUrl) {
      if (jumpToUrl) {
        window.location.href = url;
      } else {
        filterLoadUrl(id, url);
      }
    }

    // show hide clear button
    const clearBtn = document.querySelector(
      '.filters[data-id="' + id + '"] .filter-btn'
    );
    if (clearBtn) {
      if (isFiltered) {
        clearBtn.classList.remove('filter-btn--hidden');
      } else {
        clearBtn.classList.add('filter-btn--hidden');
      }
      // clearBtn.style.visibility = isFiltered ? 'visible' : 'hidden';
    }

    return false;
  }

  function filterOr(el, id) {
    if (el.id === 'sector') {
      document.querySelector(
        '.filter[data-id="service"] .filter-select-input'
      ).value = '';
    } else if (el.id === 'service') {
      document.querySelector(
        '.filter[data-id="sector"] .filter-select-input'
      ).value = '';
    }
  }

  function filterInit(id) {
    const filterLoadMore = document.querySelector(
      '.filter-results[data-id="' + id + '"] [data-action="filterLoadMore"]'
    );
    if (filterLoadMore) {
      filterLoadMore.addEventListener('click', function (event) {
        const url = filterLoadMore.getAttribute('href');
        filterLoadUrl(id, url, false);

        event.preventDefault();
      });
    }
  }

  document.querySelectorAll('.filters').forEach((el) => {
    const id = el.getAttribute('data-id');
    filters[id] = [];
    filters[id].rootUrl = el.getAttribute('data-rootUrl');
    filters[id].currentUrl = el.getAttribute('data-currentUrl');
    filters[id].updateUrl =
      el.getAttribute('data-updateUrl') === 'true' ?? false;
    filters[id].rootUrlHasQueryString =
      el.getAttribute('data-rootUrlHasQueryString') === 'true' ?? false;
    filterInit(id);
  });

  function filterClear(id) {
    document
      .querySelectorAll(
        '.filters[data-id="' +
          id +
          '"] .filter-select-input, .filters[data-id="' +
          id +
          '"] .filter-text-input'
      )
      .forEach((el) => {
        el.value = '';
        // el.dispatchEvent(new Event('change'));
      });
    filterSearch(id);
  }

  // filter keyword input delay
  function delay(callback, ms) {
    let timer = 0;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  document.querySelectorAll('.filter__button').forEach((el) => {
    el.addEventListener('click', function (event) {
      el.parentNode.querySelector('.input').value =
        el.getAttribute('data-value');
      document.querySelectorAll('.filter__button').forEach((btn) => {
        btn.classList.remove('btn--filled');
      });
      el.classList.add('btn--filled');
      const id = el.closest('.filters').getAttribute('data-id');
      filterSearch(id);
      event.preventDefault();
    });
  });

  document.querySelectorAll('.filter-select-input').forEach((el) => {
    el.addEventListener(
      'change',
      delay(function (event) {
        const id = el.closest('.filters').getAttribute('data-id');
        filterOr(el, id);
        filterSearch(id);
      }, 500)
    );
  });

  document.querySelectorAll('.filter-text-input').forEach((el) => {
    el.addEventListener(
      'keydown',
      delay(function (event) {
        if (event.key !== 'Tab') {
          const id = el.closest('.filters').getAttribute('data-id');
          filterSearch(id);
        }
      }, 500)
    );
  });

  document.querySelectorAll('.filter-btn').forEach((el) => {
    el.addEventListener('click', function (event) {
      const id = el.closest('.filters').getAttribute('data-id');
      filterClear(id);
    });
  });

  if (window.innerWidth > 1000) {
    if (document.querySelector('.filters--with-toggle')) {
      document
        .querySelector('.filters--with-toggle')
        .setAttribute('aria-hidden', false);
    }
  }

  // document
  //   .querySelectorAll('[aria-controls="filters"], [data-type="filterToggle"]')
  //   .forEach((el) => {
  //     el.addEventListener('click', function (event) {
  //       if (
  //         document.querySelector('#filters').getAttribute('aria-hidden') ===
  //         'true'
  //       ) {
  //         el.querySelector('.btn__text').innerHTML = 'Filter results';
  //       } else {
  //         el.querySelector('.btn__text').innerHTML = 'Hide filter';
  //       }
  //     });
  //   });
});

// ╭────────────────────────―╮
// │ Service tiles filtering │
// ╰─────────────────────────╯

function getControllingFilters(servicesId) {
  return document.querySelectorAll(
    '[data-filter][aria-controls="' +
      servicesId +
      '"], .js-service-filter-select[aria-controls="' +
      servicesId +
      '"]'
  );
}

function getServicesFilterElements() {
  const servicesFilterElements = {
    serviceFilterBtns: document.querySelectorAll('.js-service-filter-btn'),
    serviceFilterSelects: document.querySelectorAll(
      '.js-service-filter-select'
    ),
  };

  return servicesFilterElements;
}

/**
 * Add filter to the services element
 *
 * @param {String} filter
 * @param {Element} services
 * @returns
 */
function filterServices(filter, services) {
  if (typeof filter !== 'string' || !services) {
    return;
  }

  // Disable all filters
  const filterControls = getControllingFilters(services.id);
  filterControls.forEach((control) => {
    control.setAttribute('disabled', 'disabled');
  });

  // Fade out
  services.classList.add('services-tiles--filtering');

  services.classList.remove('visible'); // Cards go back to pre-entry state
  services.classList.add('iv-filtered'); // Add iv- class to enable visible animation when we're ready
  services.classList.remove('iv-once'); // Prevent in-view from adding visible class

  // Wait for animation
  setTimeout(function () {
    // Apply filter
    services.setAttribute('data-filter', filter);

    // Show parent el again
    services.classList.remove('services-tiles--filtering');

    setTimeout(function () {
      // Animate cards in
      services.classList.add('visible');

      // Enable filters again
      filterControls.forEach((control) => {
        control.removeAttribute('disabled');
      });
    }, 200);
  }, 250);
}

/**
 * Unpress all service filter btns
 *
 * @param {Element} serviceFilterBtns
 */
function unpressServiceFilterButtons(serviceFilterBtns) {
  serviceFilterBtns.forEach((btn) => {
    btn.setAttribute('aria-pressed', 'false');
  });
}

/**
 * Setup event listeners for filter buttons
 */
function setupServiceFilterButtons() {
  // Get elements
  const { serviceFilterBtns, serviceFilterSelects } =
    getServicesFilterElements();

  serviceFilterBtns.forEach((btn) => {
    btn.addEventListener('click', function (e) {
      const btn = e.currentTarget;
      const wasPressed = btn.getAttribute('aria-pressed');
      const servicesId = btn.getAttribute('aria-controls');
      const services = document.querySelector('#' + servicesId);

      // unpress all selects
      unsetServiceFilterSelects(serviceFilterSelects);

      // unpress all btns
      unpressServiceFilterButtons(serviceFilterBtns);

      // Press this one if it was unpressed
      if (wasPressed === 'false') {
        // press this one
        btn.setAttribute('aria-pressed', 'true');
      }

      // Set filter base on btn's pressed state
      const filter = wasPressed === 'false' ? btn.dataset.filter : '';

      // Filter the services
      filterServices(filter, services);
    });
  });
}

/**
 * Resets the service filter select
 *
 * @param {Element} serviceFilterSelect
 */
function unsetServiceFilterSelects(serviceFilterSelects) {
  if (!serviceFilterSelects) {
    return;
  }

  serviceFilterSelects.forEach((select) => {
    // Unset value
    select.value = '';

    // Remove active class
    select.classList.remove('is-active');
  });
}

function setupServiceFilterSelects() {
  // Get elements
  const { serviceFilterBtns, serviceFilterSelects } =
    getServicesFilterElements();

  serviceFilterSelects.forEach((select) => {
    select.addEventListener('change', function () {
      const servicesId = select.getAttribute('aria-controls');
      const services = document.querySelector('#' + servicesId);

      const filter = select.value;

      // unpress all btns
      unpressServiceFilterButtons(serviceFilterBtns);

      if (filter.length) {
        select.classList.add('is-active');
      } else {
        select.classList.remove('is-active');
      }

      // Filter the services
      filterServices(filter, services);
    });
  });
}

document.addEventListener('DOMContentLoaded', function () {
  setupServiceFilterButtons();

  setupServiceFilterSelects();
});
