import objectFitImages from 'object-fit-images';
import './header';
import './expanders';
import './menu';
import './marquees';
import './slider';
import './video';
import './filter';
import './in-view';

objectFitImages();

document.addEventListener('DOMContentLoaded', () => {
  /**
   * Initialize aria visibility toggles.
   *
   * Usage:
   *    <button aria-expanded="false" aria-controls="myDropdown">Toggle</button>
   *    <div id="myDropdown" aria-hidden="true">Dropdown</div>
   */
  document.querySelectorAll('[aria-expanded][aria-controls]').forEach((el) => {
    el.addEventListener('click', (e) => {
      const id = el.getAttribute('aria-controls');
      const controlled = document.getElementById(id);
      const open = controlled.getAttribute('aria-hidden') !== 'false';

      controlled.setAttribute('aria-hidden', open ? 'false' : 'true');

      document.querySelectorAll(`[aria-controls="${id}"]`).forEach((el) => {
        el.setAttribute('aria-expanded', open ? 'true' : 'false');
      });

      e.preventDefault();
    });
  });

  /**
   * Lazy loading fallback
   */
  // if ('loading' in HTMLImageElement.prototype) {
  //   const images = document.querySelectorAll('img[loading="lazy"]');
  //   images.forEach((img) => {
  //     if (img.dataset.src !== 'undefined') img.src = img.dataset.src;
  //     if (img.dataset.srcset !== 'undefined') img.srcset = img.dataset.srcset;
  //     img.classList.remove('blur-up');
  //     img.classList.remove('lazyload');
  //   });
  // } else {
  //   // Dynamically import the LazySizes library
  //   const script = document.createElement('script');
  //   script.src =
  //     'https://cdnjs.cloudflare.com/ajax/libs/lazysizes/5.1.2/lazysizes.min.js';
  //   document.body.appendChild(script);
  // }
});
