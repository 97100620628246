// Expand & contract details & summary items

import animate from 'animate-vanilla-js';

function getExpanders() {
  return document.querySelectorAll('.js-expander-item');
}

function setupExpanders() {
  const expanderItems = getExpanders();

  if (expanderItems) {
    expanderItems.forEach((item) => {
      const toggle = item.querySelector('.js-expander-item-toggle');
      const dropdownWrapper = item.querySelector('.dropdown-wrapper');
      const durationVar = getComputedStyle(item, null).getPropertyValue(
        '--expander-duration'
      );
      const duration = durationVar ? durationVar.slice(0, -2) : 150;

      toggle.addEventListener('click', function (event) {
        // first a guard clause: don't do anything
        // if we're already in the middle of closing the menu.
        if (item.classList.contains('item-closing')) {
          return;
        }
        // but, if the menu is open ...
        if (item.open) {
          // prevent default to avoid immediate removal of "open" attribute
          event.preventDefault();
          // add a CSS class that contains the animating-out code
          item.classList.add('item-closing');

          animate(
            dropdownWrapper.clientHeight,
            0,
            duration,
            'easeInOutQuad',
            (value) => {
              dropdownWrapper.style.height = `${value}px`;
            }
          ).then(() => {
            dropdownWrapper.style.cssText = '';
            item.removeAttribute('open');
            item.classList.remove('item-closing');
          });
        } else {
          event.preventDefault();
          item.setAttribute('open', '');

          // Get the height before it's visible
          dropdownWrapper.style.opacity = '0';
          dropdownWrapper.style.position = 'absolute';
          const wrapperHeight = dropdownWrapper.clientHeight;
          dropdownWrapper.style.opacity = '';
          dropdownWrapper.style.position = '';
          animate(0, wrapperHeight, duration, 'easeInOutQuad', (value) => {
            dropdownWrapper.style.height = `${value}px`;
          }).then(() => {
            dropdownWrapper.style.cssText = '';
          });
        }
      });
    });
  }
}

document.addEventListener('DOMContentLoaded', function () {
  setupExpanders();
});
